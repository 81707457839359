import Dashboard from 'screens/dashboard';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// @mui icons
import Icon from '@mui/material/Icon';
import PollsScreen from 'screens/polls/PollsScreen';
import { UserRoles } from 'constants/UserRoles';
import MemberDashboardScreen from 'screens/dashboard/MemberDashboardScreen';
import RegrionsScreen from 'screens/regions/RegionsScreen';
import RegistryScreen from 'screens/registry/RegistryScreen';
import AddressesScreen from 'screens/addresses/AddressesScreen';

const routes = [
  {
    type: 'collapse',
    name: 'General',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    type: 'collapse',
    name: 'General',
    key: 'dashboard/general',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard/general',
    component: <MemberDashboardScreen />,
    roles: [UserRoles.Member],
  },
  {
    type: 'collapse',
    name: 'Registru',
    key: 'dashboard/registry',
    icon: <MenuBookIcon fontSize="small"></MenuBookIcon>,
    route: '/dashboard/registry',
    component: <RegistryScreen />,
    roles: [UserRoles.SuperAdmin],
  },
  {
    type: 'collapse',
    name: 'Adrese',
    key: 'dashboard/addresses',
    icon: <PictureAsPdfIcon fontSize="small"></PictureAsPdfIcon>,
    route: '/dashboard/addresses',
    component: <AddressesScreen />,
    roles: [UserRoles.SuperAdmin],
  },
  {
    type: 'collapse',
    name: 'Poll-uri',
    key: 'dashboard/polls',
    icon: <HowToVoteIcon fontSize="small"></HowToVoteIcon>,
    route: '/dashboard/polls',
    component: <PollsScreen />,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    type: 'collapse',
    name: 'Unitati',
    key: 'dashboard/regions',
    icon: <GroupsIcon fontSize="small"></GroupsIcon>,
    route: '/dashboard/regions',
    component: <RegrionsScreen />,
    roles: [UserRoles.SuperAdmin],
  },
  {
    type: 'collapse',
    name: 'Postari',
    key: 'dashboard/posts',
    icon: <GroupsIcon fontSize="small"></GroupsIcon>,
    route: '/dashboard/posts',
    component: <RegrionsScreen />,
    roles: [UserRoles.SuperAdmin],
  },
];

export default routes;
