import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetPostsQuery, useDeletePostMutation } from 'api/post';
import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import BasicTable from 'components/common/Table';
import MDBox from 'components/material/MDBox';
import MDTypography from 'components/material/MDTypography';
import MDButton from 'components/material/MDButton';
import { toast } from 'react-toastify';

const PostsScreen = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const { data, isLoading } = useGetPostsQuery();
  const [deletePost, { isLoading: isDeleting }] = useDeletePostMutation();
  const [deleteDialog, setDeleteDialog] = useState({ open: false, post: null });

  const handleEdit = (post) => {
    navigate(`/dashboard/posts/edit/${post.id}`);
  };

  const handleDeleteClick = (post) => {
    setDeleteDialog({ open: true, post });
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePost(deleteDialog.post.id).unwrap();
      toast.success('Postare ștearsă cu succes!');
    } catch (error) {
      toast.error('A apărut o eroare la ștergerea postării.');
    } finally {
      setDeleteDialog({ open: false, post: null });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialog({ open: false, post: null });
  };

  const DeleteConfirmationDialog = () => (
    <Dialog open={deleteDialog.open} onClose={handleDeleteCancel}>
      <DialogTitle>Confirmare ștergere</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sunteți sigur că doriți să ștergeți postarea "{deleteDialog.post?.title}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleDeleteCancel} color="primary">
          Anulează
        </MDButton>
        <MDButton onClick={handleDeleteConfirm} color="error" loading={isDeleting}>
          Șterge
        </MDButton>
      </DialogActions>
    </Dialog>
  );

  const columns = [
    { Header: '# (ID)', accessor: 'id', align: 'left' },
    { Header: 'Titlu', accessor: 'title', align: 'left' },
    { Header: 'Autor', accessor: 'author', align: 'left' },
    { Header: 'Data', accessor: 'createdAt', align: 'center' },
    { Header: 'Acțiuni', accessor: 'actions', align: 'center' },
  ];

  useEffect(() => {
    if (data) {
      const mappedData = data?.data?.map((post) => ({
        entry: {
          id: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {post.id}
            </MDTypography>
          ),
          title: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {post.title}
            </MDTypography>
          ),
          author: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {post.author.name}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(post.createdAt).format('L')}
            </MDTypography>
          ),
          actions: (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton color="primary" onClick={() => handleEdit(post)}>
                <Icon>edit</Icon>
              </IconButton>
              <IconButton color="error" onClick={() => handleDeleteClick(post)}>
                <Icon>delete</Icon>
              </IconButton>
            </div>
          ),
        },
      }));

      setRows(mappedData);
    }
  }, [data]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteConfirmationDialog />
      <Card>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
              Postari
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDButton
              variant="gradient"
              color="info"
              startIcon={<AddIcon />}
              onClick={() => navigate('/dashboard/posts/create')}
            >
              Adauga Postare
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox>
          <BasicTable rows={rows} columns={columns} pageSize={10} />
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default PostsScreen;
