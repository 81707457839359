import React, { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MDBox from 'components/material/MDBox';
import MDPagination from 'components/material/MDPagination';
import MDTypography from 'components/material/MDTypography';
import styled from 'styled-components';

const PaginationWithEllipsis = ({ totalPages, currentPage, onPageChange }) => {
  const getPageNumbers = () => {
    const maxPagesToShow = 7;
    const halfPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfPagesToShow) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + halfPagesToShow >= totalPages) {
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - halfPagesToShow;
      endPage = currentPage + halfPagesToShow;
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  const pageNumbers = getPageNumbers();

  return (
    <MDBox display="flex" justifyContent="center" alignItems="center">
      {currentPage > 1 && (
        <MDPagination item onClick={() => onPageChange(1)}>
          1
        </MDPagination>
      )}
      {currentPage > 3 && <span>...</span>}
      {pageNumbers.map((page) => (
        <MDPagination
          key={page}
          item
          onClick={() => onPageChange(page)}
          color={page === currentPage ? 'primary' : 'default'}
          style={{
            backgroundColor: page === currentPage ? '#3498db' : 'transparent',
            color: page === currentPage ? 'white' : 'gray',
          }}
        >
          {page}
        </MDPagination>
      ))}
      {currentPage < totalPages - 2 && <span>...</span>}
      {currentPage < totalPages && (
        <MDPagination item onClick={() => onPageChange(totalPages)}>
          {totalPages}
        </MDPagination>
      )}
    </MDBox>
  );
};

const StyledTableContainer = styled(TableContainer)`
  .MuiTableRow-root:nth-of-type(even) {
    background-color: #f8f9fa;
  }

  .MuiTableCell-root {
    font-size: 0.875rem;
    color: #344767;
  }
`;

export default function BasicTable({
  rows,
  columns,
  pageSize,
  usersTable = false,
  reversePages = false,
}) {
  const [pageIndex, setPageIndex] = useState(0);

  const columnKeys = useMemo(() => columns.map((c) => c.accessor), [columns]);

  const totalPages = useMemo(() => {
    return Math.ceil(rows.length / pageSize);
  }, [rows, pageSize]);

  useEffect(() => {
    if (pageIndex >= totalPages) setPageIndex(0);
  }, [rows, totalPages]);

  const groupedRows = useMemo(() => {
    var grouped = {};
    for (var i = 0; i < rows.length; i++) {
      var p = rows[i]['region'];
      if (!grouped[p]) {
        grouped[p] = [];
      }
      grouped[p].push(rows[i]);
    }
    return Object.values(grouped).reduce((prev, current) => {
      const copy = [...current];
      return [...prev, ...copy.map((c, index) => ({ ...c, index }))];
    }, []);
  }, [rows]);

  return (
    <StyledTableContainer>
      <Table aria-label="simple table" style={{ minWidth: 800 }}>
        <TableHead style={{ display: 'table-header-group' }}>
          <TableRow style={{ width: '100%' }}>
            <>
              {usersTable && <TableCell align="center">#</TableCell>}
              {columns.map((column) => (
                <TableCell key={column.Header} align="center">
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    {column.Header.toUpperCase()}
                  </MDTypography>
                </TableCell>
              ))}
            </>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedRows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).map((row, index) => (
            <React.Fragment key={index}>
              {usersTable &&
              index > 0 &&
              groupedRows[index + pageIndex * pageSize].region !==
                groupedRows[index + pageIndex * pageSize - 1].region ? (
                <TableRow
                  key={index}
                  style={{ height: 48, backgroundColor: '#F0F0F0' }}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <>
                    <TableCell align="center"></TableCell>
                    {columnKeys.map((_key, index) => (
                      <TableCell key={index} align="center"></TableCell>
                    ))}
                  </>
                </TableRow>
              ) : null}
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <React.Fragment>
                  {usersTable && <TableCell align="center">{row.index + 1}</TableCell>}
                  {columnKeys.map((key, index) => (
                    <TableCell key={`${key}-${index}`} align="center">
                      {row.entry?.[key]}
                    </TableCell>
                  ))}
                </React.Fragment>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <MDBox p={4}>
        <PaginationWithEllipsis
          totalPages={totalPages}
          currentPage={pageIndex + 1}
          onPageChange={(page) => setPageIndex(page - 1)}
        />
      </MDBox>
    </StyledTableContainer>
  );
}
