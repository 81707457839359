import React from 'react';
import styled from 'styled-components';
import Icon from '@mui/material/Icon';
import MDBox from 'components/material/MDBox';
import MDTypography from 'components/material/MDTypography';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100px;
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: #1a73e8;
    background-color: rgba(26, 115, 232, 0.04);
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const PreviewWrapper = styled(MDBox)`
  margin-top: 16px;
  width: 100%;

  img {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const FileInput = ({ onChange, preview, accept = 'image/*' }) => {
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange(file);
    }
  };

  return (
    <MDBox>
      <InputWrapper>
        <input type="file" onChange={handleChange} accept={accept} />
        <Icon sx={{ fontSize: 40, color: '#1A73E8', mb: 1 }}>cloud_upload</Icon>
        <MDTypography variant="button" color="text">
          Trage sau click pentru a încărca imaginea
        </MDTypography>
        <MDTypography variant="caption" color="text" sx={{ opacity: 0.7 }}>
          PNG, JPG până la 5MB
        </MDTypography>
      </InputWrapper>

      {preview && (
        <PreviewWrapper>
          <img src={preview} alt="Preview" />
        </PreviewWrapper>
      )}
    </MDBox>
  );
};

export default FileInput;
