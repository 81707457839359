import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import Image from '@tiptap/extension-image';
import Typography from '@tiptap/extension-typography';
import '../../assets/styles/rte.css';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const addImage = () => {
    const url = window.prompt('Enter the URL of the image:');
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  const menuItems = [
    {
      icon: 'looks_one',
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive('heading', { level: 1 }),
      tooltip: 'Heading 1',
    },
    {
      icon: 'looks_two',
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive('heading', { level: 2 }),
      tooltip: 'Heading 2',
    },
    {
      icon: 'text_fields',
      action: () => editor.chain().focus().setParagraph().run(),
      isActive: () => editor.isActive('paragraph'),
      tooltip: 'Paragraph',
    },
    {
      icon: 'format_bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
      tooltip: 'Bold',
    },
    {
      icon: 'format_italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
      tooltip: 'Italic',
    },
    {
      icon: 'strikethrough_s',
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike'),
      tooltip: 'Strikethrough',
    },
    {
      icon: 'format_list_bulleted',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
      tooltip: 'Bullet List',
    },
    {
      icon: 'format_list_numbered',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList'),
      tooltip: 'Numbered List',
    },
    {
      icon: 'format_align_left',
      action: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.isActive({ textAlign: 'left' }),
      tooltip: 'Align Left',
    },
    {
      icon: 'format_align_center',
      action: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.isActive({ textAlign: 'center' }),
      tooltip: 'Align Center',
    },
    {
      icon: 'format_align_right',
      action: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.isActive({ textAlign: 'right' }),
      tooltip: 'Align Right',
    },
    {
      icon: 'format_align_justify',
      action: () => editor.chain().focus().setTextAlign('justify').run(),
      isActive: () => editor.isActive({ textAlign: 'justify' }),
      tooltip: 'Justify',
    },
    {
      icon: 'image',
      action: addImage,
      tooltip: 'Add Image',
    },
  ];

  return (
    <div className="menuBar">
      {menuItems.map((item, index) => (
        <Tooltip key={index} title={item.tooltip} placement="top">
          <button
            onClick={item.action}
            className={item.isActive && item.isActive() ? 'is-active' : ''}
          >
            <Icon>{item.icon}</Icon>
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

const TipTapEditor = ({ value, onChange, initialContent = '', placeholder = '' }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2],
          HTMLAttributes: {
            class: (attributes) => {
              if (attributes.level === 1) return 'heading-one';
              if (attributes.level === 2) return 'heading-two';
              return '';
            },
          },
        },
      }),
      Image.configure({
        HTMLAttributes: {
          class: 'editor-image',
        },
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph', 'image'],
      }),
      Typography,
      Highlight,
    ],
    content: initialContent,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  return (
    <div className="editor-wrapper">
      <MenuBar editor={editor} />
      <EditorContent value={value} editor={editor} placeholder={placeholder} />
    </div>
  );
};

export default TipTapEditor;
