import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TipTapEditor from 'components/rte/TipTapEditor';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import MDBox from 'components/material/MDBox';
import MDButton from 'components/material/MDButton';
import Card from '@mui/material/Card';
import { useUpdatePostMutation, useGetPostQuery } from 'api/post';
import MDTypography from 'components/material/MDTypography';
import Input from 'components/controlled/Input';
import Textarea from 'components/controlled/Textarea';
import FileInput from 'components/controlled/FileInput';
import '../../assets/styles/rte.css';

const EditPostScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});

  const { data, isLoading: getLoading } = useGetPostQuery(id);
  const [updatePost, { isLoading, isError, isSuccess }] = useUpdatePostMutation();

  useEffect(() => {
    if (!data) return;
    setPost(data);
    if (data.headerImageUrl) {
      setImagePreview(data.headerImageUrl);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      toast.error('A aparut o problema. Va rugam incercati mai tarziu.');
    }
    if (isSuccess) {
      toast.success('Postare actualizată cu succes!');
      navigate('/dashboard/posts');
    }
  }, [isError, isSuccess, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
    // Clear error when user types
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleEditorChange = (value) => {
    setPost({ ...post, content: value.toString('html') });
  };

  const handleImageChange = (file) => {
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error('Imaginea este prea mare. Dimensiunea maximă este de 5MB.');
        return;
      }
      setPost({ ...post, headerImage: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!post.title) newErrors.title = 'Titlul este obligatoriu';
    if (!post.description) newErrors.description = 'Descrierea este obligatorie';
    if (!post.content) newErrors.content = 'Conținutul este obligatoriu';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append('title', post.title);
    formData.append('description', post.description);
    formData.append('content', post.content);
    if (post.headerImage) {
      formData.append('headerImage', post.headerImage);
    }

    updatePost({ id, data: formData });
  };

  if (getLoading || !post) return <p>Loading...</p>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ padding: 3 }}>
        <MDTypography variant="h6" mb={3} gutterBottom>
          Editare Postare
        </MDTypography>
        <MDBox mb={3}>
          <Input
            label="Titlu"
            name="title"
            value={post.title}
            onChange={handleInputChange}
            error={errors.title}
          />
        </MDBox>
        <MDBox mb={3}>
          <Textarea
            label="Descriere scurtă"
            name="description"
            value={post.description}
            onChange={handleInputChange}
            multiline
            rows={3}
            error={errors.description}
          />
        </MDBox>
        <MDBox mb={3}>
          <FileInput onChange={handleImageChange} preview={imagePreview} accept="image/*" />
        </MDBox>
        <MDBox mb={3}>
          <TipTapEditor
            onChange={handleEditorChange}
            initialContent={post.content}
            placeholder="Conținutul postării..."
          />
          {errors.content && (
            <span style={{ color: '#fd5c70', fontSize: '0.75rem' }}>{errors.content}</span>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="info" onClick={handleSubmit} loading={isLoading}>
            Salvează
          </MDButton>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default EditPostScreen;
