import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || '100px'};
  padding: 0.75rem;
  border: 1px solid ${({ error }) => (error ? '#fd5c70' : '#d2d6da')};
  border-radius: 0.375rem;
  background-color: transparent;
  font-size: 0.875rem;
  color: #344767;
  transition: all 0.2s ease-in-out;
  resize: ${({ resize }) => resize || 'vertical'};
  font-family: inherit;
  line-height: 1.5;

  &:focus {
    outline: none;
    border-color: #1a73e8;
  }

  &:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
    resize: none;
  }

  &::placeholder {
    color: #6c757d;
  }
`;

const Label = styled.label`
  position: absolute;
  top: ${({ hasValue }) => (hasValue ? '-0.75rem' : '0.75rem')};
  left: 0.75rem;
  padding: 0 0.25rem;
  background-color: white;
  color: ${({ error }) => (error ? '#fd5c70' : '#6c757d')};
  font-size: ${({ hasValue }) => (hasValue ? '0.75rem' : '0.875rem')};
  transition: all 0.2s ease-in-out;
  pointer-events: none;

  ${StyledTextarea}:focus ~ & {
    top: -0.75rem;
    font-size: 0.75rem;
    color: #1a73e8;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  color: #fd5c70;
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const CharacterCount = styled.div`
  position: absolute;
  bottom: -1.25rem;
  right: 0;
  font-size: 0.75rem;
  color: ${({ isNearLimit }) => (isNearLimit ? '#fd5c70' : '#6c757d')};
`;

const Textarea = forwardRef(
  (
    {
      label,
      error,
      value,
      onChange,
      name,
      disabled = false,
      placeholder = '',
      minHeight,
      resize = 'vertical',
      maxLength,
      rows,
      ...rest
    },
    ref
  ) => {
    const hasValue = value !== undefined && value !== '';
    const characterCount = value?.length || 0;
    const isNearLimit = maxLength && characterCount >= maxLength * 0.9;

    return (
      <TextareaWrapper>
        <StyledTextarea
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          error={error}
          placeholder={placeholder}
          minHeight={minHeight}
          resize={resize}
          maxLength={maxLength}
          rows={rows}
          {...rest}
        />
        {label && (
          <Label hasValue={hasValue} error={error}>
            {label}
          </Label>
        )}
        {error && typeof error === 'string' && <ErrorMessage>{error}</ErrorMessage>}
        {maxLength && (
          <CharacterCount isNearLimit={isNearLimit}>
            {characterCount}/{maxLength}
          </CharacterCount>
        )}
      </TextareaWrapper>
    );
  }
);

Textarea.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  minHeight: PropTypes.string,
  resize: PropTypes.oneOf(['none', 'vertical', 'horizontal', 'both']),
  maxLength: PropTypes.number,
  rows: PropTypes.number,
};

export default Textarea;
