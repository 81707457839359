import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TipTapEditor from 'components/rte/TipTapEditor';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import MDBox from 'components/material/MDBox';
import MDButton from 'components/material/MDButton';
import Card from '@mui/material/Card';
import { useCreatePostMutation } from 'api/post';
import MDTypography from 'components/material/MDTypography';
import Input from 'components/controlled/Input';
import Textarea from 'components/controlled/Textarea';
import FileInput from 'components/controlled/FileInput';
import '../../assets/styles/rte.css';

const DEFAULT_POST = {
  title: '',
  content: '',
  description: '',
  headerImage: null,
};

const CreatePostScreen = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState(DEFAULT_POST);
  const [createPost, { isLoading, isError, isSuccess }] = useCreatePostMutation();
  const [imagePreview, setImagePreview] = useState(null);
  const [editorValue, setEditorValue] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isError) {
      toast.error('A aparut o problema. Va rugam incercati mai tarziu.');
    }
    if (isSuccess) {
      toast.success('Postare creata cu succes!');
      navigate('/dashboard/posts');
    }
  }, [isError, isSuccess, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleEditorChange = (value) => {
    setEditorValue(value);
    setPost({ ...post, content: value });
    if (errors.content) {
      setErrors({ ...errors, content: null });
    }
  };

  const handleImageChange = (file) => {
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error('Imaginea este prea mare. Dimensiunea maximă este de 5MB.');
        return;
      }
      setPost({ ...post, headerImage: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!post.title) newErrors.title = 'Titlul este obligatoriu';
    if (!post.description) newErrors.description = 'Descrierea este obligatorie';
    if (!post.content) newErrors.content = 'Conținutul este obligatoriu';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append('title', post.title);
    formData.append('description', post.description);
    formData.append('content', post.content);
    if (post.headerImage) {
      formData.append('headerImage', post.headerImage);
    }

    createPost(formData);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={3}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <MDBox>
              <MDTypography variant="h6" gutterBottom>
                Adaugă Postare
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox>
            <MDBox mb={3}>
              <Input
                label="Titlu"
                name="title"
                value={post.title}
                onChange={handleInputChange}
                error={errors.title}
              />
            </MDBox>
            <MDBox mb={3}>
              <Textarea
                label="Descriere scurtă"
                name="description"
                value={post.description}
                onChange={handleInputChange}
                rows={3}
                error={errors.description}
                maxLength={500}
              />
            </MDBox>
            <MDBox mb={3}>
              <FileInput onChange={handleImageChange} preview={imagePreview} accept="image/*" />
            </MDBox>
            <MDBox mb={3}>
              <TipTapEditor
                value={editorValue}
                onChange={handleEditorChange}
                placeholder="Începeți să scrieți postarea dvs. aici..."
              />
              {errors.content && (
                <span style={{ color: '#fd5c70', fontSize: '0.75rem' }}>{errors.content}</span>
              )}
            </MDBox>
            <MDButton variant="gradient" color="info" onClick={handleSubmit} loading={isLoading}>
              Creează Postare
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default CreatePostScreen;
