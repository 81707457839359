import MDTypography from 'components/material/MDTypography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import styled from 'styled-components';
import { useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'store/userSlice';
import { useGetUsers } from './useGetUsers';

const TruncatedText = styled(MDTypography)`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export default function useMembers(onDelete, onRegionHover) {
  const { data, error, isLoading } = useGetUsers();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialTotalRef = useRef(0);

  useEffect(() => {
    if (data?.entities && !initialTotalRef.current) {
      initialTotalRef.current = data.entities.length;
    }
  }, [data]);

  const handleEditUser = (user) => {
    dispatch(setCurrentUser(user));
    navigate(`/dashboard/user/${user.id}`);
  };

  return {
    isLoading,
    error,
    totalMembers: initialTotalRef.current,
    columns: [
      { Header: 'nume', accessor: 'name', align: 'left' },
      { Header: 'cnp', accessor: 'cnp', align: 'center' },
      { Header: 'addresa', accessor: 'address', align: 'center' },
      { Header: 'judet', accessor: 'county', align: 'center' },
      { Header: 'unitate', accessor: 'region', align: 'center', width: '150px' },
      { Header: 'data adeziunii', accessor: 'joinDate', align: 'center' },
      { Header: 'email', accessor: 'email', width: '20%', align: 'center' },
      { Header: 'actiuni', accessor: 'actions', align: 'center' },
    ],

    rows: data
      ? data.entities.map((user) => ({
          region: user.region.name,
          id: user.id,
          entry: {
            name: (
              <MDTypography variant="button" fontWeight="medium" sx={{ color: '#000000' }}>
                {user.name}
              </MDTypography>
            ),
            cnp: (
              <MDTypography variant="button" fontWeight="medium" sx={{ color: '#000000' }}>
                {user.userProfile.cnp}
              </MDTypography>
            ),
            address: (
              <MDTypography variant="button" fontWeight="medium" color="text">
                {user.userProfile.address}
              </MDTypography>
            ),
            email: (
              <TruncatedText variant="button" fontWeight="medium" color="text">
                {user.email}
              </TruncatedText>
            ),
            region: (
              <TruncatedText
                variant="button"
                fontWeight="medium"
                onMouseEnter={(e) => onRegionHover(e, user.region.name)}
                onMouseLeave={() => onRegionHover(null, '')}
              >
                {user.region.name}
              </TruncatedText>
            ),
            county: (
              <MDTypography variant="button" fontWeight="medium" color="text">
                {user.counties.reduce((previous, { name }) => {
                  return previous ? previous + ', ' + name : name;
                }, '')}
              </MDTypography>
            ),
            joinDate: user.userProfile.joinDate ? (
              <MDTypography variant="button" fontWeight="medium" color="text">
                {moment(user.userProfile.joinDate).format('DD/MM/YYYY')}
              </MDTypography>
            ) : null,
            actions: (
              <div style={{ display: 'flex' }}>
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    handleEditUser(user);
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>

                <IconButton
                  color={'error'}
                  onClick={() => {
                    onDelete(user);
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            ),
          },
        }))
      : [],
  };
}
